import * as React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import SearchFriendlyHelmet from './SearchFriendlyHelmet'
import './all.sass'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({ children }) => {
  const {
    facebook,
    description,
    instagram,
    paypal,
    themeColor,
    title,
    twitter,
  } = useSiteMetadata()

  return (
    <div>
      <SearchFriendlyHelmet title={title} description={description} lang="en" />
      <Navbar />
      <div>{children}</div>
      <Footer
        facebook={facebook}
        instagram={instagram}
        paypal={paypal}
        themeColor={themeColor}
        twitter={twitter}
      />
    </div>
  )
}

export default TemplateWrapper
