import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { FaPaypal } from 'react-icons/fa'
import { IoLogoFacebook, IoLogoInstagram, IoLogoTwitter } from 'react-icons/io'
import './styles.sass'

const Footer = class extends React.Component {
  render() {
    const { facebook, instagram, paypal, themeColor, twitter } = this.props
    return (
      <footer className="footer has-background-primary has-text-white">
        <div className="content has-text-centered has-background-primary has-text-white">
          <div className="social-list-container">
            <ul className="social-list">
              <li>
                <OutboundLink href={`https://facebook.com/${facebook}/`}>
                  <IoLogoFacebook color={themeColor} size={32} />
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href={`https://twitter.com/${twitter}/`}>
                  <IoLogoInstagram color={themeColor} size={32} />
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href={`https://instagram.com/${instagram}/`}>
                  <IoLogoTwitter color={themeColor} size={32} />
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href={`https://paypal.me/${paypal}/`}>
                  <FaPaypal color={themeColor} size={32} />
                </OutboundLink>
              </li>
            </ul>
          </div>
          <p>
            {`Copyright © ${new Date().getFullYear()} Lisa Nelson.`} All rights
            reserved.
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer
