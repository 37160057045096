import { graphql, useStaticQuery } from 'gatsby'

const config = {
  url: 'https://kingdomsoulcare.org',
  image: '/img/logo.png',
  twitter: 'ladnelson',
  instagram: '@ladnelson',
  paypal: '@ladnelson',
  facebook: 'lisa.d.nelson.712',
  location: 'Elgin, IL',
  name: 'Lisa Nelson',
  themeColor: '#863b85',
  bgColor: '#ffffff',
}

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  return { ...config, ...site.siteMetadata }
}

export default useSiteMetadata
